<template>
  <div class="content">
    <div class="card-box" v-if="isAdmin()">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.operator") }}</span>
          <el-select class="inp" size="small" v-model="form.agent_id" :placeholder="$t('g.choose')"
            @change="func.OperatorChange">
            <el-option v-for="item in render.operatorList" :key="item.id" :label="item.label" :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- active-value=1 inactive-value=0 -->
    <div class="card-box">
      <div class="switch_title">逾期扣费设置</div>
      <div class="charging">
        <div class="charging_title">扣费计算示例</div>
        <div class="charging_txt">
          <div>续租购买套餐后剩余天数：当前购买套餐天数-(逾期天数-免扣天数)*续租折扣</div>
          <div>示例(假设逾期10天，套餐30天)：30-(10-{{ form.grace_period }})*{{ (form.continue_percentages / 100)
            }}={{ 30 - (10 - form.grace_period) * (form.continue_percentages / 100) }}</div>
          <div class="harging_example">退租后剩余押金：押金-(逾期天数-免扣天数)*每日逾期罚金*退租折扣</div>
          <div>示例(假设押金300元，逾期10天)：300-(10-{{ form.grace_period }})*{{ form.deduction_price
            + '*' + (form.return_percentages / 100)
            }}={{ 300 - (10 - form.grace_period) * form.deduction_price * (form.return_percentages / 100) }}</div>
        </div>
      </div>
      <el-form :model="form" label-width="140px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="免扣天数">
              <el-input v-model="form.grace_period" oninput="value=value.replace(/^\.+|[^\d.]/g,'')">
                <template #append>天</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="逾期免罚金额标准">
              <el-input v-model="form.immunization_money" oninput="value=value.replace(/^\.+|[^\d.]/g,'')">
                <template #append>元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="每日逾期罚金">
              <el-input v-model="form.deduction_price" oninput="value=value.replace(/^\.+|[^\d.]/g,'')">
                <template #append>元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="续租折扣">
              <el-input v-model="form.continue_percentages" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :min="0"
                :max="100">
                <template #append>%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="退租折扣">
              <el-input v-model="form.return_percentages" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" :min="0"
                :max="100">
                <template #append>%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="逾期扣费上限">
              <el-input v-model="form.max_money" oninput="value=value.replace(/^\.+|[^\d.]/g,'')">
                <template #append>元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="overdue_tips">
              重要提示：(文本中的{time}代表还剩下几天逾期，{money}代表逾期费用，{day}代表套餐续期扣除天数,这是针对用户计算的动态数值，请不要进行修改，只修改文本或调换{time}位置即可。请注意编写的文案能搭配自己的逾期费用设置,下面左边是示例,右边是转化后的参考效果)
            </div>
          </el-col>
          <el-col :span="11">
            <div class="overdue_title">套餐到期前弹框提醒文案</div>
            <el-input v-model="form.before_message" type="textarea" :rows="8" resize="none">
              <template #append>%</template>
            </el-input>
          </el-col>
          <el-col :span="11" :offset="2">
            <div class="overdue_title">转化后的文案参考效果</div>
            <div class="example">{{ msg }}</div>
          </el-col>
        </el-row>
        <el-row class="mg_top">
          <el-col :span="11">
            <div class="overdue_title">逾期时提醒文案</div>
            <el-input v-model="form.after_message" type="textarea" :rows="8" resize="none">
              <template #append>%</template>
            </el-input>
          </el-col>
          <el-col :span="11" :offset="2">
            <div class="example example_top">{{ msg2 }}</div>
          </el-col>
        </el-row>
      </el-form>
      <el-button class="switch_box_btn" type="primary" round @click="func.save">保存设置</el-button>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from "vue";
import { updateOperators, operatorList } from "@/data/default";
import { toDay } from "@/common/func";
import axios from "axios";
import { ElMessage } from "element-plus";
import i18n from "@/data/i18n/index";
import { isOperator, isAdmin } from "@/data/token";

export default {
  name: "ExpensesAgents",
  setup() {
    const t = i18n.global.t;
    const render = {
      operatorList
    };
    const formData = reactive({
      valOperator: "",
      switchData: {
        IsSms: false,
        IsIdVerify: false,
        IsReturnClear: false,
        // IsBatInfoSave:0,
        IsCoupon: false,
        IsStage: false,
        IsPull: false,
        // IsMch:0,
        IsRentAndCommbo: false,
        IsTTS: false,
        IsLateFee: false
      },
    });
    let form = reactive({
      agent_id: '',
      grace_period: 0,//免扣期限
      deduction_price: 0,//每日扣除金额
      continue_percentages: 0,//续租折扣
      return_percentages: 0,//退租折扣
      immunization_money: 0,//逾期免罚金额标准
      max_money: 0,//逾期金额扣除上限
      before_message: '您好！您的套餐将在{time}后到期，请在到期前进行购买套餐续期或进行还电，否则套餐到期后将进行逾期扣费计算，逾期的费用随着逾期的天数增长。',
      after_message: '您好！您的套餐已逾期{time}天，逾期费用是{money}元，可用套餐续期扣除天数抵扣逾期费用，套餐续期扣除的天数为{day}天,请立即购买套餐续期或进行还电，购买套餐可抵扣部分逾期费用，逾期的费用随着逾期的天数增长。'
    })
    const func = {
      async save() {
        let primary;
        if (!form.agent_id && isAdmin()) {
          ElMessage({
            type: "error",
            message: "请选择运营商",
          });
          return;
        }

        primary = form
        let res = await axios.post(`/admin/overdue_management/edit`, primary);
        if (res) {
          ElMessage({
            type: "success",
            message: t("g.success"),
          });
        } else {
          ElMessage({
            type: "error",
            message: t("err_code.20005"),
          });
        }
      },
      async OperatorChange() {
        const params = {};
        if (isAdmin()) {
          params.agent_id = form.agent_id;
        }
        let res = await axios.get("/admin/overdue_management/get", { params });
        if (res) {
          form.agent_id = res.agent_id
          form.grace_period = res.grace_period
          form.deduction_price = res.deduction_price
          form.continue_percentages = res.continue_percentages
          form.return_percentages = res.return_percentages
          form.immunization_money = res.immunization_money
          form.max_money = res.max_money
          form.before_message = res.before_message
          form.after_message = res.after_message
        } else {
          form.grace_period = 0,//免扣期限
            form.deduction_price = 0,//每日扣除金额
            form.continue_percentages = 0,//续租折扣
            form.return_percentages = 0,//退租折扣
            form.immunization_money = 0,//逾期免罚金额标准
            form.max_money = 0,//逾期金额扣除上限
            form.before_message = '您好！您的套餐将在{time}后到期，请在到期前进行购买套餐续期或进行还电，否则套餐到期后将进行逾期扣费计算，逾期的费用随着逾期的天数增长。',
            form.after_message = '您好！您的套餐已逾期{time}天，逾期费用是{money}元，可用套餐续期扣除天数抵扣逾期费用，套餐续期扣除的天数为{day}天,请立即购买套餐续期或进行还电，购买套餐可抵扣部分逾期费用，逾期的费用随着逾期的天数增长。'
        }
      },
    };
    onMounted(() => {
      if (!isAdmin()) {
        func.OperatorChange();
      }
    });
    const msg = computed(() => {
      let txt = form.before_message.replace(/{time}/g, '3')
      return txt
    })
    const msg2 = computed(() => {
      let txt = form.after_message.replace(/{time}/g, '5')
      txt = txt.replace(/{money}/g, '50')
      txt = txt.replace(/{day}/g, '5')
      return txt
    })
    return {
      func,
      render,
      formData,
      isOperator,
      isAdmin,
      form,
      msg,
      msg2
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    .inp {
      width: 100%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

@media screen and (max-width: 800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}

.switch_box_switch {
  width: 25%;
  margin-bottom: 30px;
}

.switch_title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: left;
}

.switch_box_left {
  display: flex;
  flex-wrap: wrap;
}

.switch_box_switch span {
  display: inline-block;
  width: 115px;
  text-align: left;
}

.switch_box_btn {
  margin: 0 auto;
  margin-top: 50px;
}

.example {
  text-align: left;
}

.overdue_title {
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
}

.overdue_tips {
  text-align: left;
  margin-bottom: 15px;
}

.mg_top {
  margin-top: 30px;
}

.example_top {
  margin-top: 36px;
}

.charging_title {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.charging_txt {
  text-align: left;
  margin-bottom: 30px;
}

.harging_example {
  margin-top: 12px;
}
</style>