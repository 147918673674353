<template>
    <div class="content">
      <div class="card-box">
        <div class="header">
          <span class="title">{{$t("g.condition")}}</span>
        </div>
        <div class="inp-list">
          <div class="item" v-if="!isOperator()">
          <span class="label">上级运营商</span>
          <el-select class="inp" size="small" v-model="formData.optValue" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.id" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
          <div class="item">
            <span class="label">{{$t('member.agent_name')}}</span>
            <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('member.agent_name')"></el-input>
          </div>
          <!-- <div class="item">
            <span class="label">{{$t('member.status')}}</span>
            <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.statuses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </div> -->
          <!-- <div class="item" v-if="!isOperator()">
            <span class="label">{{$t('member.agent_code')}}</span>
            <el-input class="inp" size="small" v-model="formData.valCode" :placeholder="$t('member.agent_code')"></el-input>
          </div> -->
        </div>
        <div class="btn-ct">
          <el-button @click="func.search" class="search" type="primary" size="small">{{$t('g.search')}}</el-button>
        </div>
      </div>
      <div class="card-box data-table">
        <div class="header header_flex">
            <span class="title">{{$t("g.unit_num")}}:{{formData.total}}，{{$t("g.per_page")}}:{{formData.pageSize}}</span>
          <el-button @click="func.add_show" type="primary"  :icon="Plus">新增</el-button>
        </div>
        <el-table
             @row-click="func.showDetails"
              :data="formData.tableData"
              style="width: 100%">
              <el-table-column
                prop="Id"
                label="#"
                width="80">
              </el-table-column>
              <el-table-column
                prop="Name"
                :label="$t('member.agent_name')"
                width="140">
              </el-table-column>
              <el-table-column
                prop="NamePath"
                label="运营商归属" :show-overflow-tooltip='true'>
              </el-table-column>
              <!-- <el-table-column width="160" :label="$t('member.status')">
                <template #default="scope">
                  <el-tag :type="scope.row.IsValid == 1 ? 'success' : 'info'" effect="dark">
                    {{ scope.row.IsValid == 1 ? $t('expenses.statuses.enabled') : $t('expenses.statuses.disabled') }}
                  </el-tag>
                </template>
              </el-table-column> -->
              <el-table-column
                prop="CreateAtStr"
                width="180"
                :label="$t('g.create_at')">
              </el-table-column>
              <el-table-column
                width="125"
                :label="$t('g.operation')"
                fixed="right"
              >
                <template #default="scope">
                  <el-tag
                    @click="func.rowDblClick(scope.row)"
                  >{{$t('g.edit')}}</el-tag>
                  <!-- <el-tag
                    type="danger"
                    class="tag_left"
                    @click="func.del(scope.row)"
                  >{{$t('g.delete')}}</el-tag> -->
                </template>
              </el-table-column>
            </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="func.pageChange"
          :page-size="formData.pageSize"
          :current-page="formData.curPage"
          :total="formData.total">
        </el-pagination>
      </div>
      <el-dialog
        :title="formData.EditType=='edit'?'修改':'新增'"
        v-model="formData.dialogVisible"
        width="85%">
        <!-- label-width="80px" -->
        <el-form  :model="formData.curData" label-width="120px">
          <div class="el-form-row">
             <el-form-item label="顶级运营商" v-show="formData.EditType=='add'" v-if="!isOperator()">
              <el-select class="inp" size="small" v-model="formData.curData.paretCode" placeholder="请选择归属的顶级运营商">
                  <el-option
                    v-for="item in render.operatorList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="一级运营商名称" v-show="formData.EditType=='add'">
              <el-input size="small" v-model="formData.curData.name" maxlength="6" placeholder="请输入6个字以内一级运营商名称"></el-input>
            </el-form-item>
          </div>
          <!-- <div class="el-form-row" v-show="formData.EditType=='add'">
            <el-form-item label="二级代理商编码" >
              <el-input size="small"  v-model="formData.curData.code" oninput="value=value.replace(/[^a-zA-Z]/g, '')"  maxlength="4" placeholder="请输入不超过4个字母的英文编码,如东城曹操输入DCCC"></el-input>
            </el-form-item>
          </div> -->
          <div class="el-form-row">
            <!-- <el-form-item :label="$t('member.status')"  >
              <el-select class="inp" size="small" v-model="formData.curData.isValid" :placeholder="$t('g.choose')">
                <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="微信商户号" v-show="formData.EditType=='edit'" >
              <el-input size="small" type="number" v-model="formData.curData.mchid" placeholder="支付相关微信商户"></el-input>
            </el-form-item>
            <el-form-item label="微信商户号密码" v-show="formData.EditType=='edit'">
              <el-input size="small" type="password" v-model="formData.curData.password" placeholder="不需要修改不填"></el-input>
            </el-form-item>
          </div>
          <div class="el-form-row">
            <el-form-item :label="$t('member.mchid_zfb')" v-show="formData.EditType=='edit'">
              <el-input size="small" type="number" v-model="formData.curData.AliAppid"></el-input>
            </el-form-item>
            
          </div>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="func.updateData()">{{$t('g.btn_confirm')}}</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { reactive,onMounted,computed } from 'vue'
  import { shortcuts,updateOperators,operators,operatorList} from '@/data/default'
  import { toDay } from '@/common/func'
  import axios from "axios"
  import { ElMessage,ElMessageBox } from 'element-plus'
  import i18n from '@/data/i18n/index'
  import { isOperator } from '@/data/token'
  import { Plus } from '@element-plus/icons-vue'
  export default {
    name: 'ExpensesAgents',
    setup () {
      const t  = i18n.global.t
      const tableData = []
      const statuses = [
        {
          label: t('expenses.statuses.all'),
          value: 0
        },
        {
          label: t('expenses.statuses.enabled'),
          value: 1
        },
        {
          label: t('expenses.statuses.disabled'),
          value: 2
        }
      ]
  
      const render = reactive({
        statuses,
        updateStatuses: [
          {
            label: t('expenses.enable'),
            value: 1
          },
          {
            label: t('expenses.disable'),
            value: 0
          }
        ],
        shortcuts,
        operators,
        options:[],
        operatorList
      })
      const formData = reactive({
        tableData,
        curData: {},
        valTime: '',
        valStatus: 0,
        dialogVisible: false,
        dialogDisplayVisible:false,
        curPage: 1,
        pageSize: 10,
        total: 500,
        valName:'',
        valCode:'',
        EditType:'edit',
        optValue:'',
        props:{
            children:"Children",expandTrigger: 'hover',
            label:"Name",
            value:"Code",
            emitPath:false
        }
      })
      const func = {
        showDetails(row,column){
          if(column&&column.label==t('g.operation')){
            return;
          }
          formData.dialogDisplayVisible = true;
          formData.curData = row;
       },
        search () {
          console.log('formData', formData)
          func.loadData()
        },
        async del(row) {
          ElMessageBox.confirm('删除后相关联的包括订单、用户、套餐、电柜归属等数据会丢失', {
            confirmButtonText: t('confirm'),
            cancelButtonText: t('cancel'),
          }).then(() => {
            try {
              axios.post('/admin/agent/delete', {
                id:row.Id
              }).then((res) => {
                if (res == '删除成功') {
                  ElMessage({
                    type: 'success',
                    message: t('g.success')
                  })
                } else {
                  ElMessage({
                    type: 'error',
                    message: t("err_code.20005")
                  })
                }
                func.loadData()
              })

            } catch (error) {
              ElMessage({
                type: 'error',
                message: t("err_code.20005")
              })
            }
          })
        },
        add_show(){
          formData.curData = {}
          formData.EditType = 'add'
          formData.dialogVisible = true
        },
        rowDblClick (row, column, e) {
          console.log('rowDblClick', row, column, e)
          formData.EditType = 'edit'
          formData.curData = row
          formData.curData.mchid = row.Mchid
          formData.curData.isValid = row.IsValid
          formData.curData.name = row.Name
          formData.dialogVisible = true
        },
        pageChange (val) {
          console.log('page change', val)
          func.loadData(val)
        },
        showAddDataDialog(){
          formData.curData = {
          }
          formData.dialogVisible = true
        },
        async updateData () {
          let res
          if(localStorage.getItem('user_info')){
            let user_info = JSON.parse(localStorage.getItem('user_info'))
            if(user_info.AgentCode){
              formData.curData.paretCode = user_info.AgentCode
            }
          }
          if(!formData.curData.name || !formData.curData.paretCode&&formData.EditType=='add'){
            alert('请填写完整')
            return
          }
          if(formData.EditType == 'add'){
            // if(formData.curData.code.length!=4){
            //   alert('请填写四位数的英文编码')
            //   return
            // }
            let params = {
              paretCode:formData.curData.paretCode,
              name:formData.curData.name,
              mchid: formData.curData.mchid,
              isValid: formData.curData.isValid,
              password: formData.curData.password,
              // code:formData.curData.code,
            }
            res = await axios.post(`/admin/agent/secondagentdoadd`, params)
            console.log(res,'添加结果')
          }else{
            let params = {
                id:formData.curData.Id,
                name:formData.curData.name,
                mchid: formData.curData.mchid,
                isValid: formData.curData.isValid,
                password: formData.curData.password,
              }
            res = await axios.post('/admin/agent/secondagentedit',params)
          }
          if(res){
              formData.dialogVisible = false
              ElMessage({
                  type: 'success',
                  message: t('g.success')
              })
              func.loadData()
          }
        },
        async loadData(page=1){
          try{
            formData.tableData = []
            formData.curPage = page
            let params = {
                page,
                level:1
            }
            if(formData.valName){
                params.name = formData.valName
            }
            if(formData.optValue){
                params.code = formData.optValue
            }
            let res = await axios.get(`/admin/agent`,{
              params
            })
            console.log(res,'aaaa')
            res.List.map((item)=>{
              item.CreateAtStr = toDay(item.CreateAt)
            })
            formData.total = res.Total
            formData.tableData = res.List
            formData.pageSize = res.PageSize
          }catch(e){
            console.log("loadData",e)
          }
        }
      }
      onMounted(()=>{
        func.loadData()
        console.log(isOperator())
      })
      return {
        func,
        render,
        formData,
        isOperator,
        Plus
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .content{
    box-sizing: border-box;
    padding: 1.25rem;
    margin-bottom: 6.25rem;
    >.card-box{
      font-size: 0.875rem;
      background-color: #fff;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      padding: 1.25rem;
      border-radius: 0.3rem;
      margin-bottom: 1.25rem;
      .header{
        text-align:left;
        margin-bottom: 1.25rem;
        position: relative;
        >.title{
          font-size:1rem;
          font-weight: 500;
        }
        >.header-icons{
          position: absolute;
          right: 10px;
          top: 0px;
          z-index: 100;
          .header-icon{
            font-size: 0.875rem;
            font-weight: 500;
            border: 1px solid #ddd;
            color:#969696;
            padding: 0.25rem;
            border-radius: 50%;
            color: pointer;
            margin-left: 0.625rem;
            cursor: pointer;
          }
        }
      }
      >.btn-ct{
        text-align: right;
        :deep(.el-button--small){
          padding: 0.625rem 2rem;
        }
      }
      .inp-list{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        >.item{
          width: 49%;
          display: flex;
          align-items: center;
          margin-bottom: 1.25rem;
          .label{
            margin-right: 0.625rem;
            width: 4.375rem;
          }
          :deep(.el-range-editor--small.el-input__inner){
            flex: 1;
          }
          .inp{
            flex: 1;
          }
        }
      }
    }
    >.el-alert-my{
      margin-bottom: 1.25rem;
      box-shadow: $box-shadow;
      padding: 1.25rem;
      .el-alert-my-txt{
        font-size: 0.875rem;
        >.tips{
          font-weight: bold;
        }
      }
      :deep(.el-alert__closebtn){
        top: 1.25rem;
        font-size: 1rem;
      }
    }
    >.data-table{
      :deep(.el-table){
        .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .el-table__row{
          cursor: pointer;
        }
      }
    }
    >.pagination{
      text-align: right;
      margin-top: 1.25rem;
    }
    .el-form-row{
      display:flex;
      justify-content: space-between;
      :deep(.el-form-item){
        width: 49%;
      }
      .inp{
        width: 100%;
      }
    }
    .el-select-my{
      text-align: left;
      display: block;
    }
    :deep(.m-dialog){
      max-width: 50rem;
    }
  }
  
  .header_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tag_left{
    margin-left: 10px;
  }
  @media screen and (max-width:800px) {
      .content{
        >.card-box{
          .inp-list{
            >.item{
              width: 100%;
            }
          }
        }
      }
  }
  </style>
  