<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('receive_list.query.current_status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valstatus" :placeholder="$t('g.choose')" clearable>
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('receive_list.coupon') }}</span>
          <el-select class="inp" size="small" v-model="formData.valCouponId" :placeholder="$t('g.choose')" clearable>
            <el-option v-for="item in render.couponList" :key="item.value" :label="item.label" :value="item.value">
              <span style="margin-right:20px">{{ item.AgentCode }}</span>
              <span>{{ item.label }}</span>
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('receive_list.receive_phone') }}</span>
          <el-input class="inp" size="small" v-model="formData.valphone"
            :placeholder="$t('receive_list.receive_phone')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-cascader  size="small" style="width: 100%;flex: 1;" :clearable="true" v-model="formData.CodePath"
            :props="formData.props" :options="render.cascade_options_direct" />
        </div>
      </div>
      <div class="btn-ct">
        <el-button type="primary" @click="func.newActivity" class="new-btn" size="small">{{ $t('device.add')
          }}</el-button>
        <el-button @click="func.clear" class="search" type="info" size="small">{{ $t('g.clear') }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
        </div>

      </div>
      <el-table :data="formData.tableData" @row-click="func.showDetails" :stripe="true" style="width: 100%"
        class="el-table">
        <el-table-column prop="Id" label="#" width="70px" fixed>
        </el-table-column>
        <el-table-column prop="Code" :label="$t('receive_list.coupon_code')" width="80px" fixed
          :show-overflow-tooltip="true">
        </el-table-column>
        
        <el-table-column prop="Coupon.AgentCode" label="代理商" width="80px" fixed>
        </el-table-column>
        <el-table-column prop="NamePath" min-width="180" label="运营商归属" :show-overflow-tooltip='true'>
        </el-table-column>
        <!-- <el-table-column prop="OrderNum" :label="$t('receive_list.order_code')" width="190px"
          :show-overflow-tooltip="true">
        </el-table-column> -->
        <el-table-column prop="User.Mobile" :label="$t('receive_list.receive_phone')" width="130px">
          <template #default="scope">
            {{ scope.row.PhoneNum || scope.row.User.Mobile }}
          </template>
        </el-table-column>
        <el-table-column prop="User.Id" :label="$t('receive_list.receive_id')" width="100px">
        </el-table-column>
        <el-table-column :label="$t('member.IdName')" width="80px">
          <template #default="scope">
            {{ scope.row.User && scope.row.User.IdName }}
          </template>
        </el-table-column>
        <el-table-column prop="Coupon.Name" :label="$t('receive_list.coupon')" width="140px"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="用户类型" width="140px">
          <template #default="scope">
            <!-- {{ scope.row. }} -->
          </template>
        </el-table-column>
        <el-table-column prop="Coupon.Type" :label="$t('receive_list.coupon_type')" width="130px">
          <template #default="scope">
            {{ scope.row.Coupon.Type == 0 ? $t('coupon.render.type.cash') : $t('coupon.render.type.time') }}
          </template>
        </el-table-column>
        <el-table-column prop="Coupon.Value" :label="$t('receive_list.face_value')" width="80px">
          <template #default="scope">
            {{ scope.row.Coupon.Type == 0 ? scope.row.Coupon.Value + '元' : scope.row.Coupon.Value + '天' }}
          </template>
        </el-table-column>
        <el-table-column prop="State" :label="$t('receive_list.status')" width="80px">
          <template #default="scope">
            <el-tag type="info" v-if="scope.row.State == 4 || scope.row.State == 1">{{ statusList[scope.row.State]
              }}</el-tag>
            <el-tag type="warning" v-else-if="scope.row.State == 0">{{ statusList[scope.row.State] }}</el-tag>
            <el-tag type="danger" v-else-if="scope.row.State == 3">{{ statusList[scope.row.State] }}</el-tag>
            <el-tag type="success" v-else>{{ statusList[scope.row.State] }}</el-tag>

          </template>
        </el-table-column>
        <el-table-column prop="Distributer" :label="$t('receive_list.Distributer')" width="120px"
          :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="Remark" :label="$t('receive_list.remark')" width="180px"
          :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="useTimeStr" :label="$t('receive_list.use_time')" width="180px">
        </el-table-column>
        <el-table-column prop="CreateAtStr" :label="$t('g.create_at')" width="180px">
        </el-table-column>
        <el-table-column prop="receTimeStr" :label="$t('receive_list.receive_time')" width="180px">
        </el-table-column>

        <!-- <el-table-column prop="Name" :label="$t('coupon.rule')">
        </el-table-column> -->

        <el-table-column :width="80" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag size="large" type="success" @click="func.editRule(scope.row)"
              v-if="scope.row.State == 0 || scope.row.State == 1">{{
                $t('receive_list.withdraw') }}</el-tag>
            <!-- 
            <el-tag size="large" type="success" style="margin-left: 20px;" @click="func.editRule(scope.row,1)" v-if="scope.row.State==0">使用</el-tag> -->
          </template>

        </el-table-column>
      </el-table>

    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible">
      <el-descriptions border>
        <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.coupon_code')">{{ formData.curData.Code }}</el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.order_code')">{{ formData.curData.OrderNum
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.receive_phone')">
          {{ formData.curData.PhoneNum || formData.curData.User.Mobile }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.receive_id')">{{ formData.curData.User.Id
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.IdName')">{{ formData.curData.User && formData.curData.User.IdName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.coupon')">{{ formData.curData.Coupon.Name
          }}</el-descriptions-item>

        <el-descriptions-item :label="$t('receive_list.coupon_type')">
          {{ formData.curData.Coupon.Type == 0 ? $t('coupon.render.type.cash') : $t('coupon.render.type.time') }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.face_value')">
          {{ formData.curData.Coupon.Type == 0 ? formData.curData.Coupon.Value + '元' : formData.curData.Coupon.Value +
            '天'
          }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.status')"> <el-tag type="info"
            v-if="formData.curData.State == 4">{{
              statusList[formData.curData.State] }}</el-tag>
          <el-tag type="warning" v-else-if="formData.curData.State == 0 || formData.curData.State == 1">{{
            statusList[formData.curData.State]
          }}</el-tag>
          <el-tag type="danger" v-else-if="formData.curData.State == 3">{{ statusList[formData.curData.State]
            }}</el-tag>
          <el-tag type="success" v-else>{{ statusList[formData.curData.State] }}</el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.Distributer')">{{ formData.curData.Distributer
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.remark')">{{ formData.curData.Remark
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('card.expire')">{{ formData.curData.endAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.use_time')">{{ formData.curData.useTimeStr
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('receive_list.receive_time')">{{ formData.curData.receTimeStr
          }}</el-descriptions-item>

      </el-descriptions>
    </el-dialog>
    <el-dialog :title="formData.operateType == 1 ? $t('device.add') : $t('g.edit')" v-model="formData.dialogVisible"
      class="new-el-dialog" center>
      <div class="dailog-content">
        <div class="activity-content">
          <el-form label-width="120px" class="e-form" :rules="rules" :model="formData" ref="formDom">
            <el-form-item :label="$t('receive_list.coupon')" width="100px" prop="coupon">
              <el-select class="inp" size="small" v-model="formData.couponId" :placeholder="$t('g.choose')">
                <el-option v-for="item in render.couponListTarget" :key="item.value" :label="item.label"
                  :value="item.value" :disabled="item.expired || !Boolean(item.remainNum)">
                  <span style="margin-right:20px">{{ item.AgentCode }}</span>
                  <span style="margin-right:20px">{{ item.label }}</span>
                  <span style="
                        float: right;
                        color: var(--el-text-color-secondary);
                        font-size: 13px;
                      ">{{ item.expired ? "过期" : item.remainNum }} </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('receive_list.face_value')" width="350px">
              <template v-for="item1 in render.couponListTarget">
                <template v-if="item1.type == 1">
                  {{ item1.value == formData.couponId ? item1.num + '天' : '' }}
                </template>
                <template v-else>
                  {{ item1.value == formData.couponId ? item1.num + '元' : '' }}
                </template>
              </template>

            </el-form-item>
            <el-form-item :label="$t('receive_list.num')" width="350px">
              <el-input v-model="formData.couponNum">
              </el-input>
            </el-form-item>
            <!-- <el-form-item :label="$t('receive_list.remain_num')" width="350px" >
              <template v-for="item in render.couponList">
                <template v-if="item.value===formData.couponId">
                  {{ item.DistributeNum-item.DistributedNum}}
                </template>
              </template>
            </el-form-item> -->
            <el-form-item :label="$t('receive_list.user_type')" width="350px">
              <el-radio-group v-model="formData.userType" class="ml-4">
                <el-radio :label="0">{{ $t('receive_list.user') }} </el-radio>
                <el-radio :label="1">{{ $t('receive_list.channel') }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('receive_list.receive_phone')" width="350px" style="">
              <el-input v-model="formData.phoneNum" type="textarea" :rows="10"
                :placeholder="$t('receive_list.phone_placeholder')" show-word-limit maxlength="1100"
                :autosize="{ minRows: 6, maxRows: 8 }" style="width:30vw" />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="func.updateData()">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, onMounted, computed, ref } from 'vue'
import activityStyle from '@/components/activityMode/activityStyle.vue'
import i18n from '@/data/i18n/index'
import { toDay, toDay3, toCounterTime } from '@/common/func'
import { ElMessage, ElMessageBox, ElLoading, dayjs } from 'element-plus'
import axios from "axios"
import { user, isOperator } from '@/data/token'
import { shortcuts, operators, operatorList, operatorCascader, pile, combo,cascade_options_direct } from '@/data/default'

export default {
  name: 'restrictionRule',
  components: {
    activityStyle
  },
  setup() {
    const t = i18n.global.t
    const couponList = reactive([])
    const couponListTarget = reactive([])
    const statusList = [
      t('receive_list.statuses.unreceive'),
      t('receive_list.statuses.unuse'),
      t('receive_list.statuses.used'),
      t('receive_list.statuses.expired'),
      t('receive_list.statuses.withdraw')
    ]

    const render = {
      combo,
      pile,
      operators,
      operatorList,
      operatorCascader,
      shortcuts,
      couponList,
      couponListTarget,
      statuses: [
        {
          label: t('receive_list.statuses.unreceive'),
          value: 0
        },
        {
          label: t('receive_list.statuses.unuse'),
          value: 1
        },
        {
          label: t('receive_list.statuses.used'),
          value: 2
        },
        {
          label: t('receive_list.statuses.expired'),
          value: 3
        },
        {
          label: t('receive_list.statuses.withdraw'),
          value: 4
        },
      ],
      type: [
        {
          label: t('coupon.render.type.cash'),
          value: 0
        },
        {
          label: t('coupon.render.type.time'),
          value: 1
        }
      ],
      method: [
        {
          label: t('coupon.render.method.new_user_register'),
          value: 0
        },
        {
          label: t('coupon.render.method.develop_new_user'),
          value: 1
        },
        {
          label: t('coupon.render.method.target'),
          value: 2
        },
        {
          label: t('coupon.render.method.combo_aotu'),
          value: 3
        },
        {
          label: t('coupon.render.method.h5'),
          value: 4
        },
      ],
      cascade_options_direct
    }

    const formDom = ref(null)
    const formData = reactive({
      tableData: [],
      title: '',
      operateType: 1,//操作类型 1 新增 2 编辑
      id: 0,
      userType: 0,
      dialogVisible: false,
      dialogDisplayVisible: false,
      total: 1,
      curPage: 1,
      pageSize: 10,
      props: {
        children: "Children",expandTrigger: 'hover',
        label: "Name",
        value: "CodePath",
        emitPath: false,
        checkStrictly: true,
      },
    })
    const rules = reactive({
      name: [{ required: true, message: '请输入优惠券名称', trigger: 'change' }],
      face_value: [{ required: true, message: '请输入面额', trigger: 'change' }],
      circulation: [{ required: true, message: '请输入发行量', trigger: 'change' }],
      valTime: [{ required: true, message: '请输入起止时间', trigger: 'change' }],
    })
    const func = {
      showDetails(row, column) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      search() {
        func.loadData()
      },
      newActivity() {
        formData.operateType = 1;
        formData.id = ''
        formData.userType = 0
        formData.couponNum = 1
        formData.phoneNum = ''
        formData.dialogVisible = true;
        // formDom.value.clearValidate()
      },
      async updateData() {
        formDom.value.validate(async (valid) => {
          if (valid) {
            if (formData.operateType == 1) {
              try {
                const params = {
                  couponId: formData.couponId,
                  couponNum: formData.couponNum,
                  userType: formData.userType,

                  // agentName: formData.circulation,
                  // remark: formData.remark,
                  // enable: formData.status ? false : true,
                }
                if (formData.userType == 0) {
                  params.phoneNum = formData.phoneNum.trim().replace(/\s/g, ',')
                }
                // console.log(JSON.stringify(params));
                // return 
                let res = await axios.post('/admin/couponorder/add', params)
                if (res) {
                  ElMessage({
                    type: 'success',
                    message: t('g.success')
                  })
                } else {
                  ElMessage({
                    type: 'error',
                    message: t('err_code.20017')
                  })
                }
              } catch (e) {
              }
            } else if (formData.operateType == 2) {
              try {
                const params = {
                  id: formData.id,
                  name: formData.name,
                  type: formData.type,
                  value: formData.face_value,
                  method: formData.method,
                  num: formData.circulation,
                  remark: formData.remark,
                  enable: formData.status ? false : true,
                }
                if (formData.valTime) {
                  params.startTime = Math.floor(new Date(formData.valTime[0]).getTime() / 1000)
                  params.endTime = Math.floor(new Date(formData.valTime[1]).getTime() / 1000)
                }

                let res = await axios.post('/admin/coupon/edit', params)
                if (res == '操作成功') {
                  ElMessage({
                    type: 'success',
                    message: t('g.success')
                  })
                } else {
                  ElMessage({
                    type: 'error',
                    message: t('err_code.20017')
                  })
                }
              } catch (e) {
              }
            }

            formData.dialogVisible = false
            func.loadData(formData.curPage)

          } else {

            return ElMessage({
              type: 'error',
              message: t('err_code.20017')
            })
          }
        })



      },
      async editRule(row, type = 0) {
        try {
          const params = {
            id: row.Id,
            state: 4,
          }
          if (type == 1) {
            params.state = 2;
          }
          console.log(JSON.stringify(params));
          // return 
          let res = await axios.post('/admin/couponorder/edit', params)

          if (res) {
            ElMessage({
              type: 'success',
              message: t('g.success')
            })
          } else {
            ElMessage({
              type: 'error',
              message: err.Data || t('err_code.20017')
            })
          }
          func.loadData();
        } catch (e) {
        }

      },
      clear() {
        formData.valphone = '';
        formData.valCouponId = '';
        formData.valstatus = '';
        formData.valTime = '';
        func.loadData()
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valTypes != -1) {
            params.openType = formData.valTypes
          }
          if (formData.valphone) {
            params.phoneNum = formData.valphone.trim()
          }
          if (formData.CodePath) {
            params.CodePath = formData.CodePath
          }

          params.state = formData.valstatus

          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          if (formData.valCouponId) {
            params.couponId = formData.valCouponId
          }
          let res = await axios.get('/admin/couponorder/get', {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreatedAt)
            if (item.UseTime) {
              item.useTimeStr = toDay(item.UseTime)
            }
            if (item.ReceTime) {
              item.receTimeStr = toDay(item.ReceTime)
            }
            if (item.Coupon.EndTime) {
              item.endAtStr = toDay3(item.Coupon.EndTime)
            }

            // item.startAtStr = toDay3(item.StartTime)
            // item.endAtStr = toDay3(item.EndTime)
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize

        } catch (e) {
          console.log("loadData", e)
        }
      },
      async getCoupon() {
        try {
          let page = 1
          let params = {
            page,
            enable: 1,
            getAll: 1
          }
          let res = await axios.get('/admin/coupon/get', {
            params,
          })
          res.List.map((item) => {
            couponList.push(
              {
                label: item.Name,
                value: item.Id,
                num: item.Value,
                type: item.Type,
                expired: dayjs().add(-1, 'day').isAfter(dayjs.unix(item.EndTime)),
                // item.EndTime<Math.floor((new Date()).valueOf()/1000),//,
                DistributeNum: item.DistributeNum,
                DistributedNum: item.DistributedNum,
                remainNum: item.DistributeNum - item.DistributedNum,
                AgentCode: item.AgentCode
              }
            )
            if (item.DistributeMethod == 2) {
              couponListTarget.push(
                {
                  label: item.Name,
                  value: item.Id,
                  num: item.Value,
                  type: item.Type,
                  expired: dayjs().add(-1, 'day').isAfter(dayjs.unix(item.EndTime)),
                  // item.EndTime<Math.floor((new Date()).valueOf()/1000),//,
                  DistributeNum: item.DistributeNum,
                  DistributedNum: item.DistributedNum,
                  remainNum: item.DistributeNum - item.DistributedNum,
                  AgentCode: item.AgentCode
                }
              )
            }

          })

        } catch (e) {
          console.log("loadData", e)
        }
      },
      async deleteRule(id) {
        console.log(JSON.stringify(232), id);

        try {
          console.log(JSON.stringify(id), 230);

          let res = await axios.post('/admin/coupon/delte', {
            id
          })
          console.log(JSON.stringify(res));
          if (res == '操作成功') {
            ElMessage({
              type: 'success',
              message: t('g.success')
            })
          } else {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }
          func.loadData()
        } catch (error) {
          ElMessage({
            type: 'error',
            message: t("err_code.20005")
          })
        }
      }
    }
    onMounted(() => {

      func.getCoupon()
      func.loadData()
      // console.log(JSON.stringify(operatorCascader),2830);

      // console.log(JSON.stringify(operatorList),20833);
    })
    return {
      func,
      formData,
      render,
      couponList,
      token: user.token,
      isOperator,
      rules,
      formDom,
      statusList
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 5.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    >.pagination {
      text-align: right;
      margin-top: 1.25rem;
    }

  }

  .new-el-dialog {
    .dailog-content {
      display: flex;
      // width:500px;
    }

    .activity-content {
      flex: 2
    }

    .activity-style {
      flex: 1;
    }

    .e-form {
      display: flex;
      flex-wrap: wrap;
    }

  }



  :deep(.m-dialog) {
    // max-width: 80rem;
    // margin-top: 0;
  }
}

// @media screen and (max-width: 900px) {
//   .new-el-dialog {
//     margin: 0 !important;
//     width: 100% !important;
//     padding: 30px;
//     background-color: pink !important;
//     height: 100vh;
//     position: absolute;
//     top:0;

//     .dailog-content {
//       width: 100vw;
//       padding: 30px;
//       background-color: pink;
//       display: flex;
//       // width:500px;
//     }

//     .activity-content {
//       flex: 2
//     }

//     .activity-style {
//       flex: 1;
//     }

//     .e-form {
//       display: flex;
//       flex-wrap: wrap;
//     }

//   }
// }</style>