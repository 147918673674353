import { reactive } from 'vue'
import axios from 'axios'
import i18n from '@/data/i18n/index'
const t = i18n.global.t

export const userMenuList = reactive({
  data:[]
})

export const loadUserMenu = async (userId)=>{
  setTimeout(async ()=>{
    let res = await axios.get("/admin/role/userAuth?user_id="+userId)
    const userMenu = []
    // console.log(JSON.stringify(res),23);
    const findById = (id)=>{
      for(let item of res){
        if(item.Id === id){
          return item
        }
      }
      return false
    }
    // console.log("menu",menu)
    for(let top of menu){
      let topTmp = {}
      topTmp.title = top.title
      topTmp.item = []
      for(let top2 of top.item){
        let menuItem = findById(top2.id)
        if(menuItem !== false){
          let top2Tmp = {
            // title:menuItem.ModuleName,
            title:top2.title,
            id:menuItem.Id,
            icon:'el-icon-timer',
            item:[]
          }
          //3级菜单
          for(let top3 of top2.item){
            let menuItem2 = findById(top3.id)
            if(menuItem2 !== false){
              let top3Tmp = {
                // title:menuItem2.ModuleName,
                title:top3.title,
                id:menuItem2.Id,
                name:menuItem2.Url,
              }
              top2Tmp.item.push(top3Tmp)
            }
          }
          if(top2Tmp.item.length > 0)topTmp.item.push(top2Tmp)
        }
      }
      if(topTmp.item.length > 0)userMenu.push(topTmp);
    }
    userMenuList.data = userMenu
    // console.log("userMenu",userMenu)
  },100)
}
export const findTitleByNavName = (name) => {
  if(!name){
    return ""
  }
  for(let item of menu){
    for(let item2 of item.item){
      for(let item3 of item2.item){
          if(item3.name == name){
            return item3.title
          }
      }
    }
  }
  return ""
}
const menu = [
      {
        title: t('menu.big_data'),
        item: [
          {
            title: t('menu.decision.analyze'),
            id:100,
            icon: 'el-icon-timer',
            item: [
              {
                id:127,
                title: t('menu.decision.hot'),
                name: 'DecisionHot'
              },
              {
                id:128,
                title: t('menu.decision.market'),
                name: 'DecisionMarket'
              },
              {
                id:160,
                title: t('menu.decision.statistics'),
                name: 'DecisionStatistics'
              }
            ]
          },
          {
            id:99,
            title: t('menu.decision.operation'),
            icon: 'el-icon-timer',
            item: [
              {
                id:123,
                title: t('menu.distribution_site'),
                name: 'OperationPoints'
              },
              {
                id:149,
                title: t('menu.distribution_battery'),
                name: 'OperationBattery'
              },
              {
                id:124,
                title: t('menu.decision.user'),
                name: 'OperationUsers'
              },
              {
                id:125,
                title: t('menu.decision.properties'),
                name: 'OperationProperties'
              },
              {
                id:126,
                title: t('menu.decision.order'),
                name: 'OperationOrders'
              }
            ]
          }
        ]
      },
      {
        title: t('menu.finance'),
        item: [
          {
            id:98,
            title: t('menu.expenses_center'),
            icon: 'el-icon-timer',
            item: [
              {
                id:122,
                title: t('menu.set_menu_manage'),
                name: 'ExpensesCombo'
              },
              {
                id:161,
                title: t('menu.ExpensesBeOverdue'),
                name: 'ExpensesBeOverdue'
              }
            ]
          },
          {
            id:97,
            title: t('menu.bill_manage'),
            icon: 'el-icon-timer',
            item: [
              {
                id:118,
                title: t('menu.turnover_record'),
                name: 'BillRecord'
              },
              {
                id:119,
                title: t('menu.settle'),
                name: 'BillSettle'
              },
              {
                id:120,
                title: t('menu.refund'),
                name: 'BillRefund'
              },
              {
                id:152, 
                title: t('menu.withdraw'),
                name: 'withdrawFunds'
              },
              {
                id:121,
                title: t('menu.badbill'),
                name: 'BillBad'
              },
              {
                id:130,
                title: t('menu.refund_record'),
                name: 'BillRefundRecord'
              },
              {
                id:162,
                title: t('menu.overdue'),
                name: 'overdue'
              },
            ]
          },
          {
            id:96,
            title: t('menu.orders.manage'),
            icon: 'el-icon-timer',
            item: [
              {
                id:111,
                title: t('menu.orders.rent'),
                name: 'OrderBattery'
              },
              {
                id:112,
                title: t('menu.orders.exchange'),
                name: 'OrderCabinet'
              },
              {
                id:113,
                title: t('menu.orders.return'),
                name: 'OrderBatteryReturn'
              },
              {
                id:114,
                title: t('menu.orders.cbox'),
                name: 'OrderCbox'
              },
              {
                id:115,
                title: t('menu.orders.pile'),
                name: 'OrderPile'
              },
              {
                id:116,
                title: t('menu.orders.recharge'),
                name: 'OrderRecharge'
              },
              {
                id:117,
                title: t('menu.orders.setmenu'),
                name: 'OrderCombo'
              },
              {
                id:133,
                title: t('menu.orders.offline'),
                name: 'OrderOffline'
              },
              {
                id:148,
                title: t('menu.orders.temporary_record'),
                name: 'temporaryRecord'
              },
              {
                id:150,
                title: t('menu.orders.import_record'),
                name: 'importRecord'
              }
            ]
          }
        ]
      }, {
        title: t('menu.operation'),
        item: [
          {
            id:94,
            title: t('menu.member'),
            icon: 'el-icon-timer',
            item: [
              {
                id:108,
                title: t('menu.profile'),
                name: 'MemberProfile'
              },
              {
                id:109,
                title: t('menu.member_list'),
                name: 'MemberList'
              },
              {
                id:110,
                title: t('menu.agent_list'),
                name: 'MemberAgents'
              },
              {
                id:159,
                title: t('menu.agents_switch'),
                name: 'agentsSwitch'
              },
              {
                id:164,
                title: t('menu.SecondTierAgent'),
                name: 'SecondTierAgent'
              },
              {
                id:165,
                title: t('menu.ThirdTierAgent'),
                name: 'ThirdTierAgent'
              },
              {
                id:166,
                title: t('menu.FourthTierAgent'),
                name: 'FourthTierAgent'
              }
            ]
          },
          {
            id:95,
            title: t('menu.after_sale_service'),
            icon: 'el-icon-timer',
            item: [
              {
                id:107,
                title: t('menu.feedback'),
                name: 'AfterSellFeedBack'
              }
            ]
          },
          {
            id:134,
            title: t('menu.open_solot_record'),
            icon: 'el-icon-timer',
            item: [
              {
                id:140, 
                title: t('menu.open_solot_record'),
                name: 'openSolotRecord'
              },
              {
                id:154, 
                title: t('menu.open_solot'),
                name: 'openSolot'
              }
            ]
          },
          {
            id:141,
            title: t('menu.alert_record'),
            icon: 'el-icon-timer',
            item: [
              {
                id:142, 
                title: t('menu.alert_record'),
                name: 'alertRecord'
              }
            ]
          }
          ,
          {
            id:143,//活动管理
            title: t('menu.activity_manage'),
            icon: 'el-icon-timer',
            item: [
              {
                id:147, 
                title: t('menu.restriction_rule'),
                name: 'restrictionRule'
              },
              {
                id:146, 
                title: t('menu.discount_coupon'),
                name: 'discountCoupon'
              },
              {
                id:144, 
                title: t('menu.recruit_manage'),
                name: 'activityManage'
              },
              {
                id:145, 
                title: t('menu.award_manage'),
                name: 'awardManage'
              }
              ,
              {
                id:151, 
                title: t('menu.recruit_new'),
                name: 'recruitNew'
              },
              {
                id:156, 
                title: t('menu.announcement_list'),
                name: 'announcementList'
              }
            ]
          }
        ]
      },
      {
        title: t('menu.sys'),
        item: [
          {
            id:85,
            title: t('menu.log_info'),
            icon: 'el-icon-timer',
            item: [
              {
                id:89,
                title: t('menu.log_sys'),
                name: 'LogSystem'
              },
              {
                id:90,
                title: t('menu.log_api'),
                name: 'LogApi'
              }
              ,
              {
                id:153,
                title: t('menu.msg_log'),
                name: 'msgLog'
              }
            ]
          }
        ]
      },
      {
        title: t('menu.properties'),
        item: [
          {
            id:91,
            title: t('menu.box_manage'),
            icon: 'el-icon-timer',
            item: [
              {
                id:101,
                title: t('menu.ebox_list'),
                name: 'EboxList'
              },
              {
                id:102,
                title: t('menu.cbox_list'),
                name: 'CboxList'
              },
              {
                id:129,
                title: t('menu.cpbox_list'),
                name: 'CPboxList'
              },
              {
                id:155,
                title: t('menu.electricity_stat'),
                name: 'electricityStat'
              }
            ]
          },
          {
            id:93,
            title: t('menu.pile_manage'),
            icon: 'el-icon-timer',
            item: [
              {
                id:103,
                title: t('menu.pile_list'),
                name: 'PileList'
              }
            ]
          },
          {
            id:92,
            title: t('menu.battery_manage'),
            icon: 'el-icon-timer',
            item: [
              {
                id:104,
                title: t('menu.battery_list'),
                name: 'BatteryList'
              }
            ]
          },
          {
            id:131,
            title: t('menu.card_manage'),
            icon: 'el-icon-timer',
            item: [
              {
                id:132,
                title: t('menu.card_list'),
                name: 'CardList'
              }
            ]
          }
        ]
      },
      {
        title: t('menu.rights_manage'),
        item: [
          {
            id:84,
            title: t('menu.rights_manage'),
            icon: 'el-icon-timer',
            item: [
              {
                id:88,
                title: t('menu.managers'),
                name: 'RightsManager'
              },
              {
                id:87,
                title: t('menu.role_manage'),
                name: 'RightsRole'
              },
              {
                id:86,
                title: t('menu.rights_manage'),
                name: 'Rights'
              },
            ]
          },
        ]
      }
    ]
